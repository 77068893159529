import React from 'react';
import { Box, Typography, Icon } from '@material-ui/core';
import { Palette } from '@woundtech/ui-colors';
import CanTextIcon from './CanTextIcon';

const ItemIcon: React.FC<{
  icon: string;
  value: string;
  noWrap?: boolean;
  svgIcon?: React.FC<any>;
  className?: string;
  showCanTextIcon?: boolean;
  showCanTextIconValue?: boolean;
  canTextIconValue?: string;
}> = ({
  icon,
  value,
  noWrap = true,
  svgIcon: SvgIcon,
  className,
  showCanTextIcon,
  canTextIconValue: iconValue,
}) => {
  return (
    <Box display="flex" width={1}>
      {SvgIcon ? (
        <Box pr={0.5} color={Palette.Gray.MediumDarker}>
          <SvgIcon fontSize="small" />
        </Box>
      ) : (
        <Box pr={1} color={Palette.Gray.MediumDarker} display="flex">
          <Icon fontSize="small" color="inherit">
            {icon}
          </Icon>
        </Box>
      )}
      <Typography variant="body2" noWrap={noWrap} className={className}>
        {value}
      </Typography>
      {showCanTextIcon && <CanTextIcon canTextValue={iconValue} />}
    </Box>
  );
};

export default React.memo(ItemIcon);
