import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ItemLabel from './ItemLabel';
import { attributeTextStyles } from './AttributeItemTextStyles';
import { getDataTestIdProps } from 'common/utils';
import CanTextIcon from './CanTextIcon';

const ItemTextIcon: React.FC<{
  label: string;
  value: string | string[];
  noWrap: boolean;
  highligted?: boolean;
  variant?: 'small' | 'large' | 'verysmall';
  textAlign?: 'left' | 'right';
  priorityItemTextDataTestId?: string;
  canTextIconValue?: string;
}> = ({
  label,
  value,
  noWrap,
  highligted,
  variant = 'small',
  textAlign = 'left',
  priorityItemTextDataTestId,
  canTextIconValue,
}) => {
  const classes = attributeTextStyles();
  let isFirstMobile = true;

  const formattedValue = Array.isArray(value)
    ? value
    : value.split(', ').map(num => num.trim());

  return (
    <Box
      display="flex"
      width={1}
      justifyContent={textAlign === 'right' ? 'flex-end' : 'normal'}
      {...(label === 'Priority' &&
        getDataTestIdProps(priorityItemTextDataTestId ?? ''))}
    >
      <ItemLabel text={label} variant={variant} />
      <Box display="flex" flexWrap="wrap">
        {formattedValue.map(text => {
          const isMobilePrimary =
            label === 'Phone Number' &&
            text.startsWith('(Mobile)') &&
            isFirstMobile;

          if (isMobilePrimary) {
            isFirstMobile = false;
          }
          return (
            <Box key={text} display="inline-flex" alignItems="center" mr={2}>
              <Typography
                noWrap={noWrap}
                color={highligted ? 'primary' : undefined}
                className={classes[variant]}
              >
                {text}
              </Typography>
              {isMobilePrimary && (
                <CanTextIcon canTextValue={canTextIconValue} />
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
export default React.memo(ItemTextIcon);
