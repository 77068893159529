import { Box } from '@material-ui/core';
import { FiberNewRounded } from '@material-ui/icons';
import React from 'react';

export type NewBadgeWrapperProps = {
  showBadge: boolean;
  badgeColor: string;
  children: React.ReactNode;
};
function NewBadgeWrapper({ showBadge, badgeColor, children }) {
  if (showBadge) {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <Box>{children}</Box>
        <Box style={{ position: 'absolute', top: 0, right: 0 }}>
          <FiberNewRounded color={badgeColor} />
        </Box>
      </Box>
    );
  }
  return <>{children}</>;
}

export default React.memo(NewBadgeWrapper);
