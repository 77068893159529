import {
  CONSENT_PROVISION_TYPE_PERMIT,
  MOBILE_VERBAL_CONSENT_NO,
  MOBILE_VERBAL_CONSENT_YES,
} from '../constants';

export const getVerbalConsentExtUrl = () => 'MOBILE_VERBAL_CONSENT_EXTENSION';
export const getVerbalReadOnlyExtUrl = () => 'READ_ONLY';
export const getMobileOptedOutExtUrl = () => 'OPT_OUT_USING_TEXT_REPLY';

export function getEmail(telecom: fhir.ContactPoint[] | undefined) {
  return telecom?.find(item => item.system === 'email')?.value;
}

export function getPhoneNumberExtension(
  telecom: fhir.ContactPoint | undefined
) {
  return telecom?.extension?.find(item => item.url === 'PHONE_NUMBER_EXTENSION')
    ?.valueString;
}

export function getOtherLabelExtension(telecom: fhir.ContactPoint | undefined) {
  return telecom?.extension?.find(item => item.url === 'OTHER_LABEL_EXTENSION')
    ?.valueString;
}

export function getVerbalConsentExtData(
  telecom: fhir.ContactPoint | undefined
) {
  return telecom?.extension?.find(
    item => item.url === getVerbalConsentExtUrl()
  );
}

export function getVerbalConsentExtension(
  telecom: fhir.ContactPoint | undefined
) {
  const verbalConsentData = getVerbalConsentExtData(telecom);
  if (Boolean(verbalConsentData?.valueReference)) {
    return verbalConsentData?.valueReference?.display?.toLowerCase();
  }
  return verbalConsentData?.valueString?.toLowerCase();
}

export function evaluateConsentValue(consentData) {
  if (consentData?.provision?.type === CONSENT_PROVISION_TYPE_PERMIT) {
    return MOBILE_VERBAL_CONSENT_YES;
  }
  return MOBILE_VERBAL_CONSENT_NO;
}

export function getVerbalConsentReadOnlyExtUrl(
  extension: fhir.Extension[] | undefined
) {
  return extension?.find(
    item =>
      item.url === getVerbalReadOnlyExtUrl() ||
      item.url === getMobileOptedOutExtUrl()
  );
}
export function getVerbalConsentReadOnlyExtData(
  extension?: fhir.Extension[]
): string {
  return getVerbalConsentReadOnlyExtUrl(extension)?.valueBoolean ? 'yes' : 'no';
}

export const isShowIconValid = (
  telecom: fhir.ContactPoint[] | undefined,
  isVerbalConsentEnabled: boolean
) => {
  if (!telecom || telecom.length < 1) {
    return false;
  }

  const telecomNumber = telecom.find(item => item.system === 'phone');

  return isVerbalConsentEnabled && telecomNumber?.use === 'mobile';
};
export interface ContactPointWithVerbalConsent extends fhir.ContactPoint {
  verbalConsentOptIn?: boolean | undefined;
}
