import { gql } from '@apollo/client';

export const ContactPointFragment = gql`
  fragment ContactPoint on ContactPoint {
    extension {
      url
      valueString
      valueReference {
        display
        reference
      }
    }
    rank
    system
    use
    value
  }
`;
