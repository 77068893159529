import React from 'react';
import { Tooltip } from '@material-ui/core';

export type TooltipWrapperProps = {
  isDisabled: boolean;
  title: string;
  children: React.ReactNode;
};
function TooltipWrapper({ isDisabled, title, children }) {
  if (isDisabled) {
    return (
      <React.Fragment>
        <Tooltip title={title}>
          <span>{children}</span>
        </Tooltip>
      </React.Fragment>
    );
  }
  return <>{children}</>;
}

export default React.memo(TooltipWrapper);
