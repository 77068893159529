import { gql } from '@apollo/client';

export const GetConsentByPatientIdQuery = gql`
  query GetConsentByPatientId($patientId: String) {
    GetConsentByPatientId(patientId: $patientId) {
      id
      extension {
        url
        valueBoolean
      }
      status
      patient {
        reference
      }
      provision {
        type
      }
    }
  }
`;
