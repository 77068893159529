import React, { useMemo } from 'react';
import { Icon } from '@material-ui/core';
import {
  MOBILE_VERBAL_CONSENT_NO,
  MOBILE_VERBAL_CONSENT_YES,
} from 'styleguide/form/constants';

export type CanTextValues =
  | typeof MOBILE_VERBAL_CONSENT_YES
  | typeof MOBILE_VERBAL_CONSENT_NO;

export type CanTextProps = {
  canTextValue: CanTextValues;
};
function CanTextIcon({ canTextValue }) {
  const COLOR_GRAY = '#929292';
  const COLOR_GREEN = '#27ae60';
  const COLOR_RED = '#ef5350';

  const iconColor = useMemo(() => {
    if (canTextValue) {
      return isOptedIn(canTextValue) ? COLOR_GREEN : COLOR_RED;
    }
    return COLOR_GRAY;
  }, [canTextValue, COLOR_RED, COLOR_GREEN, COLOR_GRAY]);

  const iconName = useMemo(() => {
    if (canTextValue) {
      return isOptedIn(canTextValue) ? 'check' : 'close';
    }
    return 'question_mark_outlined';
  }, [canTextValue]);

  return (
    <Icon
      style={{
        position: 'relative',
        color: iconColor,
        marginLeft: 3,
      }}
      fontSize="small"
    >
      chat_bubble_outline
      <Icon
        style={{
          position: 'absolute',
          bottom: 5,
          right: 3,
          color: iconColor,
          fontSize: 'small',
        }}
      >
        {iconName}
      </Icon>
    </Icon>
  );
}

function isOptedIn(consentValue: CanTextValues) {
  return consentValue === MOBILE_VERBAL_CONSENT_YES;
}

export default React.memo(CanTextIcon);
