import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import { Grid, Switch, FormControlLabel } from '@material-ui/core';
import { VALUE_SET_STATES_URL } from 'utility/constants';
import { getCodeableConceptCode } from 'utility/helpers';
import SingleChoiceField from 'styleguide/form/SingleChoice';
import ValueSet from 'styleguide/form/ValueSet';
import TextField, {
  INPUT_MASK_POSTAL_CODE,
  INPUT_PATTERN_WORDS,
  VALIDATION_PATTERN_POSTAL_CODE,
  VALIDATION_PATTERN_WORDS,
  MAX_LENGTH_ADDRESS,
} from 'styleguide/form/Text';
import Section from 'styleguide/layout/Section';

import { Box } from '@material-ui/core';

import SingleCountyChoice from 'market/SingleCountyChoice';
import SingleMarketChoice from 'market/SingleMarketChoice';
import {
  SERVICE_ADDRESS_URL,
  VERIFY_ADDRESS_URL,
  ADDRESS_MARKET_EXTENSION_URL,
  ADDRESS_WITH_FACILITY_TYPE,
  ADDRESS_WITH_OUT_FACILITY_TYPE,
} from '../constants';
import {
  getAddressMarketId,
  setAddressMarket,
  getIsServiceAddress,
  getIsVerifiedAddress,
} from './helperts';
import { INSTRUCTION_TYPE } from 'master-list/constants';
import HealthCareFacilityPage from 'master-list/common/Lookup';
import { getInstruction } from 'master-list/common/utils';
import {
  getCareFacilityExtension,
  withCareFacilityExtension,
  withoutCareFacilityExtension,
} from 'patient/utils';
import { CARE_FACILITY_SERVICE_EXTENSION } from 'patient/constants';
import { Context as FeatureToggleContext } from 'application/FeatureToggle';

export type Props = {
  data?: any;
  disabled?: boolean;
  disableServiceAddressToggle?: boolean;
  entities?: fhir.Organization[];
  isStateRequired?: boolean;
  maxLength?: number;
  onChange: (data: fhir.Address) => void;
  onValidate: (isValid: boolean) => void;
  required?: boolean;
  showServiceAddressToggle?: boolean;
  showVerifyAddressToggle?: boolean;
  showCounty?: boolean;
  showMarket?: boolean;
  showInstruction?: boolean;
  showFacility?: boolean;
  careFacility?: boolean;
  showFacilitybutton?: boolean;
};

const mapFormValue = (name: string, value: any, data?: fhir.Address) => {
  let newData = {
    ...(data || {}),
  };

  const line1 = data?.line?.[0] || '';
  const line2 = data?.line?.[1] || '';

  switch (name) {
    case 'line1': {
      newData['line'] = [value, line2];
      break;
    }
    case 'line2':
      newData['line'] = [line1, value];
      break;
    case 'city':
      newData['city'] = value;
      break;
    case 'postalCode':
      newData['postalCode'] = value;
      if (value) {
        newData['district'] = undefined;
        newData = setAddressMarket(newData, undefined);
      }
      break;
    case 'state':
      newData['state'] = value;
      if (value) {
        newData['district'] = undefined;
        newData = setAddressMarket(newData, undefined);
      }
      break;
    case 'district':
      newData['district'] = value;
      newData = setAddressMarket(newData, undefined);
      if (value) {
        newData = setAddressMarket(newData, undefined);
      }
      break;
    case 'market':
      newData = setAddressMarket(newData, value);
      break;
    case 'addressType':
      newData['addressType'] = value?.coding?.[0]?.code;
      break;
  }

  return newData;
};

function getEntityId(option: fhir.Organization | undefined) {
  return option?.address?.[0].state || '';
}

function getEntityLabel(option: fhir.Organization | undefined) {
  return option?.address?.[0].state || '';
}

const Address: React.FC<Props> = ({
  data,
  disabled,
  disableServiceAddressToggle,
  entities,
  isStateRequired,
  maxLength,
  onChange,
  onValidate,
  required,
  showServiceAddressToggle,
  showVerifyAddressToggle,
  showCounty,
  showMarket,
  showInstruction = false,
  showFacility = false,
  careFacility = false,
  showFacilitybutton = false,
}) => {
  const featureToggles = useContext(FeatureToggleContext);
  const [isCityValid, setIsCityValid] = useState(false);
  const [isLine1Valid, setIsLine1Valid] = useState(false);
  const [isLine2Valid, setIsLine2Valid] = useState(false);
  const [isPostalCodeValid, setIsPostalCodeValid] = useState(false);
  const [isStateValid, setIsStateValid] = useState(false);
  const [isCareFacilityValid, setIsCareFacilityValid] = useState(false);
  const [isInstructionValid, setIsInstructionValid] = useState(false);
  const [county, setCounty] = useState<string>(data?.district ?? undefined);

  const [show, setShow] = useState<boolean>(false);

  const handleChange = (value, name) => {
    onChange(mapFormValue(name || '', value, data));
  };

  const handleAddressTypeChange = useCallback(
    (value, name) => {
      const mappedValueWithData = mapFormValue(name || '', value, data);
      let extensions = [];

      let careFacilityExtensions = withCareFacilityExtension(data?.extension);
      if (value?.coding?.[0]?.code === 'home') {
        extensions = withoutCareFacilityExtension(data?.extension) || [];
      } else {
        const orgId = data?.healthCareFacility?.facilityInfo?.organization?.id;
        if (!careFacilityExtensions?.length && orgId) {
          const updatedAddress = data?.extension.push(
            getCareFacilityExtension(orgId)
          );
          extensions = updatedAddress?.extension;
        }
      }
      onChange({
        ...mappedValueWithData,
        extension: extensions,
      });
    },
    [onChange, data]
  );

  const handleStateChange = (option: fhir.CodeableConcept | undefined) => {
    onChange(mapFormValue('state', getCodeableConceptCode(option) || '', data));
  };

  const updateStateChange = (option: fhir.Organization | undefined) => {
    const value = getEntityId(option) || '';
    if (value !== data?.state) {
      onChange(mapFormValue('state', getEntityId(option) || '', data));
    }
  };

  const handleInstructionChange = useCallback(
    (value: string | undefined) => {
      const updatedInstruction: fhir.Extension = {
        url: INSTRUCTION_TYPE,
        valueString: value,
      };
      const updatedExtension =
        data?.extension?.filter(item => item?.url !== INSTRUCTION_TYPE) || [];
      onChange({
        ...(data || {}),
        extension: [updatedInstruction, ...updatedExtension],
      });
    },
    [data, onChange]
  );

  const handleInstruction = useCallback(
    (value: string | undefined) => {
      onChange({
        ...(data || {}),
        instruction: value || '',
      });
    },
    [onChange, data]
  );

  const careFacilityInstruction = useMemo(() => {
    const instructionDetails = data?.instruction;
    if (
      instructionDetails === undefined &&
      data?.healthCareFacility?.facility
    ) {
      const careFacilityInstrcution = getInstruction(
        data?.healthCareFacility?.facility?.address[0].extension
      )?.valueString;
      onChange({
        ...data,
        instruction: careFacilityInstrcution || '',
      });
      return careFacilityInstrcution;
    }
    return instructionDetails;
  }, [data, onChange]);

  const instruction = useMemo(
    () =>
      data?.extension?.find(item => item?.url === INSTRUCTION_TYPE)
        ?.valueString,
    [data]
  );

  const handleServiceAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const extension =
      data?.extension?.filter(item => {
        return (
          item.url !== SERVICE_ADDRESS_URL &&
          item.url !== ADDRESS_MARKET_EXTENSION_URL
        );
      }) || [];

    extension.push({
      url: SERVICE_ADDRESS_URL,
      valueBoolean: event.target.checked,
    });

    onChange({
      ...(data || {}),
      extension,
      district: undefined,
    });
  };

  const handleVerifyAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let addresses = data?.extension || [];
    const index = addresses.findIndex(item => item.url === VERIFY_ADDRESS_URL);
    if (index === -1) {
      addresses = [
        ...addresses,
        { url: VERIFY_ADDRESS_URL, valueBoolean: event.target.checked },
      ];
    } else {
      addresses = addresses.map((item, itemIndex) =>
        itemIndex === index
          ? { url: VERIFY_ADDRESS_URL, valueBoolean: event.target.checked }
          : item
      );
    }
    onChange({
      ...(data || {}),
      extension: addresses,
    });
  };

  const handleCountyChange = useCallback(
    (value: fhir.Location | undefined) => {
      setCounty(value?.name ?? '');
      if (data) {
        onChange(mapFormValue('district', value?.name, data));
      }
    },
    [onChange, data]
  );

  const handleMarketChange = (value: fhir.Location | undefined) => {
    data['district'] = county;
    if (data) {
      onChange(mapFormValue('market', value, data));
    }
  };

  const isValid =
    (isCityValid &&
      isLine1Valid &&
      isLine2Valid &&
      isPostalCodeValid &&
      isStateValid) ||
    isCareFacilityValid;

  useEffect(() => {
    let isFormValid = showInstruction ? isValid && isInstructionValid : isValid;
    if (data?.addressType === 'home' && isFormValid) {
      isFormValid = !!data?.state && isPostalCodeValid;
    } else if (data?.addressType === 'facility' && isFormValid) {
      isFormValid = !!data?.healthCareFacility?.facility;
    } else {
      isFormValid = isFormValid && isPostalCodeValid;
    }
    onValidate(isFormValid);
  }, [
    isValid,
    onValidate,
    showInstruction,
    isInstructionValid,
    data,
    isPostalCodeValid,
  ]);

  useEffect(() => {
    setIsCareFacilityValid(
      Boolean(data?.healthCareFacility?.contact || data?.state)
    );
  }, [data]);

  const isServiceAddress = useMemo(() => getIsServiceAddress(data), [data]);
  const isVerifiedAddress = useMemo(() => getIsVerifiedAddress(data), [data]);
  const marketValue = useMemo(() => getAddressMarketId(data), [data]);
  const lookupCardChange = (facilityAddress: any) => {
    const hasFacilityExtension = data?.extension?.find(
      item => item?.url === CARE_FACILITY_SERVICE_EXTENSION
    );
    const orgId = facilityAddress?.facilityInfo?.organization?.id;
    if (
      data?.addressType === 'facility' &&
      orgId &&
      !hasFacilityExtension?.length
    ) {
      const careFacilityExtension = getCareFacilityExtension(orgId);
      const withoutCareFacility = withoutCareFacilityExtension(data.extension);
      onChange({
        ...data,
        healthCareFacility: facilityAddress,
        postalCode: facilityAddress?.facility?.address?.[0]?.postalCode,
        extension: [...withoutCareFacility, careFacilityExtension],
      });
    }
    setIsCareFacilityValid(true);
  };

  const addCareFacility = useMemo(
    () => ({
      icon: data?.healthCareFacility?.contact ? 'sync' : 'search',
      text: data?.healthCareFacility?.contact ? 'Change' : 'Lookup',
      onClick: () => {
        setShow(true);
      },
    }),
    [data]
  );

  const isFacilitiesActive = useMemo(
    () => Boolean(featureToggles?.EnableAddressFacilities === 'true'),
    [featureToggles]
  );

  return (
    <Grid container spacing={2}>
      {(showServiceAddressToggle || showVerifyAddressToggle) && (
        <>
          {showFacility && (
            <Grid item xs={12} sm={4}>
              <SingleChoiceField
                variant="button-group"
                name="addressType"
                onChange={handleAddressTypeChange}
                onValidate={() => {}}
                options={
                  isFacilitiesActive
                    ? ADDRESS_WITH_OUT_FACILITY_TYPE
                    : ADDRESS_WITH_FACILITY_TYPE
                }
                value={data?.addressType || 'home'}
                required
              />
            </Grid>
          )}

          <Grid item xs={!showFacility ? 12 : 6} sm={!showFacility ? 12 : 8}>
            <Box display={'flex'} justifyContent={'flex-end'} p={1}>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={!!isServiceAddress}
                    onChange={handleServiceAddressChange}
                    color="primary"
                    value={!!isServiceAddress}
                  />
                }
                label={'Service Address'}
                labelPlacement="end"
                disabled={disableServiceAddressToggle}
              />
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={!!isVerifiedAddress}
                    onChange={handleVerifyAddressChange}
                    color="primary"
                    value={!!isVerifiedAddress}
                  />
                }
                label={'Verified'}
                labelPlacement="start"
              />
            </Box>
          </Grid>
        </>
      )}

      {data?.addressType === 'facility' ? (
        <Grid item xs={12}>
          <Section title="" action={addCareFacility}>
            <HealthCareFacilityPage
              onLookupClose={() => setShow(false)}
              onContactSelect={lookupCardChange}
              showModalDrawer={show}
              entities={entities}
              selectedAddress={data?.healthCareFacility}
              showFacilitybutton={showFacilitybutton}
            />
            {data?.healthCareFacility?.contact && (
              <TextField
                name="instruction"
                onChange={handleInstruction}
                onValidate={() => {}}
                label={'Instruction'}
                value={careFacilityInstruction}
                validationPattern={VALIDATION_PATTERN_WORDS}
              />
            )}
          </Section>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <TextField
              disabled={disabled}
              label="Address Line 1"
              name="line1"
              onChange={handleChange}
              onValidate={setIsLine1Valid}
              required={required}
              value={data?.line?.[0]}
              maxLength={MAX_LENGTH_ADDRESS}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={disabled}
              label="Address Line 2"
              name="line2"
              onChange={handleChange}
              onValidate={setIsLine2Valid}
              value={data?.line?.[1]}
              maxLength={MAX_LENGTH_ADDRESS}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              disabled={disabled}
              label="Postal Code"
              name="postalCode"
              onChange={handleChange}
              onValidate={setIsPostalCodeValid}
              required={required}
              validationPattern={VALIDATION_PATTERN_POSTAL_CODE}
              value={data?.postalCode}
              placeholder="99999-9999"
              mask={INPUT_MASK_POSTAL_CODE}
              maxLength={INPUT_MASK_POSTAL_CODE.length}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              disabled={disabled}
              inputPattern={INPUT_PATTERN_WORDS}
              label="City"
              name="city"
              onChange={handleChange}
              onValidate={setIsCityValid}
              required={required}
              validationPattern={VALIDATION_PATTERN_WORDS}
              value={data?.city}
            />
          </Grid>
          {entities && (
            <Grid item xs={12} sm={4}>
              <SingleChoiceField
                name="userState"
                label="State"
                value={data?.state}
                onChange={updateStateChange}
                onValidate={setIsStateValid}
                options={entities}
                variant="drop-down"
                getOptionLabel={getEntityLabel}
                getOptionId={getEntityLabel}
                required={required || isStateRequired}
              />
            </Grid>
          )}
          {!entities && (
            <Grid item xs={12} sm={4}>
              <ValueSet
                name="state"
                label="State"
                value={data?.state}
                onChange={handleStateChange}
                onValidate={setIsStateValid}
                variant="typeahead"
                required={required || isStateRequired}
                url={VALUE_SET_STATES_URL}
                disabled={disabled}
              />
            </Grid>
          )}
          {showCounty && (isServiceAddress || careFacility) && (
            <Grid item xs={12} sm={6}>
              <SingleCountyChoice
                postalCode={isPostalCodeValid ? data?.postalCode : undefined}
                state={data?.state}
                onChange={handleCountyChange}
                value={data?.district}
              />
            </Grid>
          )}
          {showMarket && (isServiceAddress || careFacility) && (
            <Grid item xs={12} sm={6}>
              <SingleMarketChoice
                county={county}
                postalCode={isPostalCodeValid ? data?.postalCode : undefined}
                state={data?.state}
                onChange={handleMarketChange}
                value={marketValue}
              />
            </Grid>
          )}
          {showInstruction && (
            <Grid item xs={12}>
              <TextField
                name="instruction"
                onChange={handleInstructionChange}
                onValidate={setIsInstructionValid}
                label={'Instruction'}
                value={instruction}
                validationPattern={VALIDATION_PATTERN_WORDS}
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default React.memo(Address);
