import {
  MOBILE_VERBAL_CONSENT_NO,
  MOBILE_VERBAL_CONSENT_YES,
} from '../constants';

export const useCases = [
  {
    coding: [
      {
        code: 'mobile',
        display: 'Mobile',
      },
    ],
  },
  {
    coding: [
      {
        code: 'home',
        display: 'Home',
      },
    ],
  },
  {
    coding: [
      {
        code: 'work',
        display: 'Work',
      },
    ],
  },
  {
    coding: [
      {
        code: 'temp',
        display: 'Other',
      },
    ],
  },
];
export type ConsentOptionCoding = {
  code: string;
  display: string;
};

export type ConsentOption = {
  coding: ConsentOptionCoding[];
};
export const mobileConsentOptions: ConsentOption[] = [
  {
    coding: [
      {
        code: MOBILE_VERBAL_CONSENT_YES,
        display: 'Yes',
      },
    ],
  },
  {
    coding: [
      {
        code: MOBILE_VERBAL_CONSENT_NO,
        display: 'No',
      },
    ],
  },
];
