import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';

import Grid from '@material-ui/core/Grid';
import SingleChoiceField from 'styleguide/form/SingleChoice';
import TextField, {
  INPUT_MASK_PHONE_NUMBER,
  INPUT_PATTERN_NUMBER,
  VALIDATION_PATTERN_PHONE_NUMBER,
} from 'styleguide/form/Text';

import { ConsentOption, mobileConsentOptions, useCases } from './constants';

import { Field } from '../types';
import {
  getPhoneNumberExtension,
  ContactPointWithVerbalConsent,
  getVerbalConsentReadOnlyExtUrl,
  getVerbalConsentExtUrl,
  getVerbalConsentExtension,
  evaluateConsentValue,
} from './helpers';
import { useLDFlagValue } from 'common/useLDFlagValue';
import { VERBAL_CONSENT } from 'common/constants';
import ToolTipWrapper from 'styleguide/Tooltip/ToolTipWrapper';
import NewBadgeWrapper from 'styleguide/Badge/NewBadgeWrapper';
import { useParams } from 'react-router-dom';
import { GetConsentByPatientIdQuery } from './Schema';

export interface Props extends Field {
  disabled?: boolean;
  onChange: (value: ContactPointWithVerbalConsent) => void;
  onValidate: (isValid: boolean) => void;
  required?: boolean;
  typeRequired?: boolean;
  value: ContactPointWithVerbalConsent | undefined;
  typeDisabled?: boolean;
  isPatient?: boolean;
  isOktoTextShown?: boolean;
}

const TelecomPhone: React.FC<Props> = ({
  value: data,
  disabled,
  onChange,
  onValidate,
  required,
  typeDisabled,
  typeRequired,
  isPatient,
  isOktoTextShown,
}) => {
  const [isNumberValid, setIsNumberValid] = useState(false);
  const [isExtensionValid, setIsExtensionValid] = useState(true);
  const [isUseValid, setIsUseValid] = useState(false);
  const [isOkToTextValid, setIsOkToTextValid] = useState(true);

  const { patientId } = useParams<{ patientId: string }>();

  const { data: consentData, loading: consentDataLoading } = useQuery(
    GetConsentByPatientIdQuery,
    {
      skip: !patientId || !isPatient || !isOktoTextShown,
      variables: { patientId },
      fetchPolicy: 'no-cache',
    }
  );

  const consentExtensions = consentData?.GetConsentByPatientId?.extension;

  const isVerbalConsentReadOnly = getVerbalConsentReadOnlyExtUrl(
    consentExtensions
  );

  const shouldShowNewBadge = useMemo(() => {
    return !getVerbalConsentExtension(data);
  }, [data]);

  const verbalConsentValue = useMemo(() => {
    if (consentData?.GetConsentByPatientId && isVerbalConsentReadOnly) {
      return evaluateConsentValue(consentData?.GetConsentByPatientId);
    } else {
      return getVerbalConsentExtension(data);
    }
  }, [data, consentData, isVerbalConsentReadOnly]);

  const handleNumberChange = (value: string) => {
    onChange({
      ...data,
      value,
    });
  };

  const handleUseChange = (use: fhir.CodeableConcept | undefined) => {
    onChange({
      ...data,
      use: use?.coding?.[0]?.code,
    });
  };

  const handleExtensionNumberChange = (value: string) => {
    const extension = [
      {
        url: 'PHONE_NUMBER_EXTENSION',
        valueString: value,
      },
    ];
    onChange({
      ...data,
      extension,
    });
  };

  const handleIsOkToTextChange = useCallback(
    (value: ConsentOption) => {
      const extension = [
        {
          url: getVerbalConsentExtUrl(),
          valueString: value?.coding?.[0]?.code,
        },
      ];
      onChange({
        ...data,
        extension,
      });
    },
    [onChange, data]
  );

  const value = data?.value?.replace(/[^0-9]+/gi, '') || '';
  const use = data?.use;
  const extension = getPhoneNumberExtension(data);

  const isVerbalConsentEnabled = useLDFlagValue(VERBAL_CONSENT);

  const isPhoneReqired =
    required ||
    (!!use && !typeDisabled) ||
    !!value ||
    (extension !== undefined && extension !== '');
  const fieldWidth =
    use === 'work' ||
    use === 'temp' ||
    (isPatient && isVerbalConsentEnabled && isOktoTextShown)
      ? 4
      : 6;
  const isValid =
    isNumberValid && isExtensionValid && isUseValid && isOkToTextValid;

  useEffect(() => {
    onValidate(isValid);
  }, [isValid, onValidate]);

  const [isOnChangeTriggered, setIsOnChangeTriggered] = useState<boolean>(
    false
  );
  useEffect(() => {
    if (isPhoneReqired && use === 'home' && !isOnChangeTriggered) {
      setIsOnChangeTriggered(true);
      onChange({
        ...data,
        use: use,
      });
    }
  }, [use, data, isPhoneReqired, onChange, isOnChangeTriggered]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={fieldWidth}>
        <SingleChoiceField
          disabled={disabled || typeDisabled}
          label="Phone Type"
          name="phoneType"
          onChange={handleUseChange}
          onValidate={setIsUseValid}
          options={useCases}
          value={use}
          variant="drop-down"
          required={typeRequired || (isPatient && isPhoneReqired)}
        />
      </Grid>
      <Grid item xs={12} sm={fieldWidth}>
        <TextField
          disabled={disabled}
          label="Phone Number"
          mask={INPUT_MASK_PHONE_NUMBER}
          maxLength={INPUT_MASK_PHONE_NUMBER.length}
          name="phoneNumber"
          onChange={handleNumberChange}
          onValidate={setIsNumberValid}
          placeholder="(999) 999-9999"
          required={isPhoneReqired}
          validationPattern={VALIDATION_PATTERN_PHONE_NUMBER}
          value={value}
        />
      </Grid>
      {data?.use === 'work' && (
        <Grid item xs={12} sm={4}>
          <TextField
            disabled={disabled}
            label="Extension"
            name="extension"
            onChange={handleExtensionNumberChange}
            onValidate={setIsExtensionValid}
            inputPattern={INPUT_PATTERN_NUMBER}
            value={extension}
          />
        </Grid>
      )}
      {isPatient && isVerbalConsentEnabled && isOktoTextShown && (
        <Grid item xs={12} sm={4}>
          <ToolTipWrapper
            isDisabled={Boolean(isVerbalConsentReadOnly)}
            title={'Consent already saved'}
          >
            <NewBadgeWrapper
              showBadge={shouldShowNewBadge}
              badgeColor={'primary'}
            >
              <SingleChoiceField
                name={'okToText'}
                onChange={handleIsOkToTextChange}
                onValidate={setIsOkToTextValid}
                label="OK to text"
                options={mobileConsentOptions}
                value={verbalConsentValue}
                variant="drop-down"
                disabled={
                  Boolean(isVerbalConsentReadOnly) || consentDataLoading
                }
                required
              />
            </NewBadgeWrapper>
          </ToolTipWrapper>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(TelecomPhone);
