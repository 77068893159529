import { Palette } from '@woundtech/ui-colors';

export enum Size {
  LARGE = 'large',
  MEDIUM = 'medium',
  XMEDIUM = 'x-medium',
  SMALL = 'small',
}

export const DEFAULT_DOCUMENT_TYPE = {
  coding: [
    {
      code: 'other',
      display: 'Other',
      system: 'http://woundtech.net/fhir/CodeSystem/document-type',
    },
  ],
  text: 'Other',
};

export enum OPTION_STATUS {
  selectedAgain = 'selectedAgain',
  selected = 'selected',
  lastSelected = 'lastSelected',
  unSelected = 'unSelected',
  disabled = 'disabled',
}

export const LAST_SELECTED_OPTION_LABEL_CSS = {
  borderBottom: '1px solid',
  borderColor: Palette.Blue.Dark,
};

export const THIN_BORDER_CSS = {
  border: '1px solid',
};

export const UPLOAD_IMAGE_PAYLOAD_TYPE = 'uploading';

export const MOBILE_VERBAL_CONSENT_YES: string = 'yes';
export const MOBILE_VERBAL_CONSENT_NO: string = 'no';

export const CONSENT_PROVISION_TYPE_PERMIT: string = 'permit';
export const CONSENT_PROVISION_TYPE_DENY: string = 'deny';
